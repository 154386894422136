import { Token } from '@noahswap/sdk'
import { evmTokens } from '@noahswap/tokens'
import { evmWarningTokens } from 'config/constants/warningTokens'

// const { bondly, itam, ccar, bttold } = evmTokens
const { pokemoney, free, safemoon } = evmWarningTokens

interface WarningTokenList {
  [key: string]: Token
}

const SwapWarningTokens = <WarningTokenList>{
  // safemoon,
  // bondly,
  // itam,
  // ccar,
  // bttold,
  // pokemoney,
  free,
}

export default SwapWarningTokens
