import { Price, Currency } from '@noahswap/sdk'
import { Text, AutoRenewIcon } from '@noahswap/uikit'
import { formatNumber } from 'utils/formatInfoNumbers'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price<Currency, Currency>
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = !showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)
  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `1 ${price?.quoteCurrency?.symbol} = ${formatNumber(formattedPrice, formattedPrice)} ${
        price?.baseCurrency?.symbol
      }`
    : `1 ${price?.baseCurrency?.symbol} = ${formatNumber(formattedPrice, formattedPrice)} ${
        price?.quoteCurrency?.symbol
      }`

  return (
    <Text style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: '14px' }}>
      {show ? (
        <>
          {label}
          <StyledBalanceMaxMini onClick={() => setShowInverted(!showInverted)}>
            {/* <AutoRenewIcon width="14px" /> */}
            <img src="/images/small-img/replace.png" width={14} alt="" />
          </StyledBalanceMaxMini>
        </>
      ) : (
        '-'
      )}
    </Text>
  )
}
