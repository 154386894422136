import styled from 'styled-components'
import { Flex, Heading, IconButton, NotificationDot, Box } from '@noahswap/uikit'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import RefreshIcon from 'components/Svg/RefreshIcon'
import { useCallback, useRef, useState } from 'react'
// import { RowBetween } from 'components/Layout/Row'
import { useTranslation } from '@noahswap/localization'
import { SettingsMode } from '../../../components/Menu/GlobalSettings/types'

interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
  allowedSlippage: number
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 0 24px;
  width: 100%;
`
const Text = styled.div`
  color: #4c4c4c;
  font-size: 14px;
  margin-right: 5px;
`

const RotatingImage = styled.img`
  &.rotating {
    animation: rotate 1s linear;
  }
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const CurrencyInputHeader: React.FC<React.PropsWithChildren<Props>> = ({
  title,
  allowedSlippage,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager()
  const imgRef = useRef<HTMLImageElement>(null)
  const [isRotating, setIsRotating] = useState(false) // 旋转状态
  // const handleOnClick = useCallback(() => onRefreshPrice?.(), [onRefreshPrice])
  const handleOnClick = useCallback(() => {
    onRefreshPrice?.()
    if (!hasAmount) return
    setIsRotating(true) // 开始旋转
    imgRef.current?.addEventListener('animationend', () => setIsRotating(false)) // 动画结束停止旋转
  }, [onRefreshPrice])

  const { t } = useTranslation()

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex" mr={18}>
          <Heading as="h2">{title}</Heading>
        </Flex>
        <Flex>
          {/* <Flex alignItems="center">
            <Text>
              {t('noah16')}: {allowedSlippage / 100}%
            </Text>
          </Flex> */}
          <NotificationDot show={expertMode}>
            <GlobalSettings color="textSubtle" mr="0" mode={SettingsMode.SWAP_LIQUIDITY} />
          </NotificationDot>
          <IconButton
            variant="text"
            scale="sm"
            onClick={handleOnClick}
            style={{ cursor: hasAmount ? 'pointer' : 'no-drop' }}
          >
            {/* <RefreshIcon disabled={!hasAmount} color="textSubtle" width="27px" /> */}
            <RotatingImage
              ref={imgRef}
              src="/images/small-img/refresh.png"
              width={22}
              alt=""
              className={isRotating ? 'rotating' : ''}
            />
          </IconButton>
        </Flex>
      </Flex>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
