/* eslint-disable */
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { ChainId, Currency, CurrencyAmount, Token, Trade, TradeType } from '@noahswap/sdk'
import {
  computeTradePriceBreakdown,
  warningSeverity,
  computeSlippageAdjustedAmounts,
  basisPointsToPercent,
} from 'utils/exchange'
import {
  Button,
  Text,
  ArrowDownIcon,
  Box,
  useModal,
  Flex,
  IconButton,
  // BottomDrawer,
  ArrowUpDownIcon,
  Skeleton,
  useMatchBreakpoints,
} from '@noahswap/uikit'
import { formatNumber } from 'utils/formatInfoNumbers'
import { useIsTransactionUnsupported } from 'hooks/Trades'
import UnsupportedCurrencyFooter from 'components/UnsupportedCurrencyFooter'
import Footer from 'components/Menu/Footer'
import { CommitButton } from 'components/CommitButton'
import { useRouter } from 'next/router'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTranslation } from '@noahswap/localization'
import { EXCHANGE_DOCS_URLS } from 'config/constants'
import { BIG_INT_ZERO } from 'config/constants/exchange'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import shouldShowSwapWarning from 'utils/shouldShowSwapWarning'
import { useSwapActionHandlers } from 'state/swap/useSwapActionHandlers'
import SettingsModal, { withCustomOnDismiss } from 'components/Menu/GlobalSettings/SettingsModal'
import { SettingsMode } from 'components/Menu/GlobalSettings/types'

// import { useCommonTokens } from 'hooks/useCommonTokens'
import useRefreshBlockNumberID from './hooks/useRefreshBlockNumber'
import { GreyCard } from '../../components/Card'
import Column, { AutoColumn } from '../../components/Layout/Column'
import ConfirmSwapModal from './components/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { AutoRow, RowBetween } from '../../components/Layout/Row'
import { ArrowWrapper, SwapCallbackError, Wrapper } from './components/styleds'
import TradePrice from './components/TradePrice'
import ProgressSteps from './components/ProgressSteps'
import { AppBody } from '../../components/App'
import ConnectWalletButton from '../../components/ConnectWalletButton'
import Link from 'next/link'
import { useCurrency, useAllTokens } from '../../hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import { useSwapCallback } from '../../hooks/useSwapCallback'
import useWrapCallback, { WrapType } from '../../hooks/useWrapCallback'
import { Field } from '../../state/swap/actions'
// import { useGasPriceManager, useDefaultGasPrice } from 'state/user/hooks'
// import { GAS_PRICE_GWEI, GAS_PRICE } from 'state/types'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapState,
  // useSingleTokenSwapInfo,
} from '../../state/swap/hooks'
import {
  useExpertModeManager,
  useUserSlippageTolerance,
  useUserSingleHopOnly,
  useExchangeChartManager,
} from '../../state/user/hooks'
import CircleLoader from '../../components/Loader/CircleLoader'
import Page from '../Page'
// eslint-disable-next-line
import ResultModal from 'components/ResultModal'
import SwapWarningModal from './components/SwapWarningModal'
// import PriceChartContainer from './components/Chart/PriceChartContainer'
import { StyledInputCurrencyWrapper, StyledSwapContainer } from './styles'
import CurrencyInputHeader from './components/CurrencyInputHeader'
import ImportTokenWarningModal from '../../components/ImportTokenWarningModal'
import { CommonBasesType } from '../../components/SearchModal/types'
import replaceBrowserHistory from '../../utils/replaceBrowserHistory'
import { currencyId } from '../../utils/currencyId'
import SwapRoute from './components/SwapRoute'
import FormattedPriceImpact from './components/FormattedPriceImpact'

import { TokenList } from '@uniswap/token-lists'
import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'
import fromPairs from 'lodash/fromPairs'
import { WrappedTokenInfo, TagInfo, TokenAddressMap, EMPTY_LIST } from '@noahswap/tokens'
import chainUtils from 'service/chainUtils'
import DEFAULT_TOKEN_LIST from '../../config/constants/tokenLists/noah-default.tokenlist.json'
import useUserAddedTokens from '../../state/user/hooks/useUserAddedTokens'
import { getRecommendCoin, get24HInfo } from 'service/service'
import { truncateHasZero } from 'utils/formatBalance'

const Label = styled(Text)`
  font-size: 14px;
  font-weight: bold;
  color: #4c4c4c;
`

const SwitchIconButton = styled(IconButton)`
  background: transparent !important;
  &:hover {
    opacity: 1 !important;
  }
`

const ButtonStyle = styled.div<{ isMobile: boolean }>`
  width: 48%;
  display: flex;
  border-radius: 6px;
  cursor: pointer;
  // justify-content: center;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  // padding: ${({ isMobile }) => (isMobile ? '10px 10px' : '10px 24px')};
  // height: 112px;
  // justify-content: flex-start;
  &:hover {
    // background-color: #fff;
  }
`
const TopBox = styled.div<{ isMobile: boolean }>`
  // padding: ${({ isMobile }) => (isMobile ? '10px 10px' : '10px 24px')};
  .label {
    color: #999;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .value {
    color: #000;
    font-size: ${({ isMobile }) => (isMobile ? '18px' : '20px')};
    font-weight: bold;
  }
`
// const CHART_SUPPORT_CHAIN_IDS = [ChainId.EVM]

// const SettingsModalWithCustomDismiss = withCustomOnDismiss(SettingsModal)

export default function Swap() {
  const router = useRouter()
  // 默认选中币种
  const loadedUrlParams = useDefaultsFromURLSearch()

  // const [gasPriceAll, setGasPriceAll] = useState({
  //   default: GAS_PRICE.default,
  // })

  // const [gasPrice, setGasPrice] = useGasPriceManager()

  // if (Number(gasPrice) < 900000000000) {
  //   setGasPrice(GAS_PRICE_GWEI.rpcDefault)
  // }

  // const defaultGasPrice = useDefaultGasPrice()
  // // console.log('首页defaultGasPrice', defaultGasPrice)

  // useEffect(() => {
  //   if (defaultGasPrice) {
  //     setGasPriceAll((prevGasPrice) => ({
  //       ...prevGasPrice,
  //       default: defaultGasPrice,
  //     }))
  //   }
  // }, [defaultGasPrice])

  const {
    t,
    currentLanguage: { code: langCode },
  } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const [isChartExpanded, setIsChartExpanded] = useState(false)
  const [userChartPreference, setUserChartPreference] = useExchangeChartManager(isMobile)
  const [isChartDisplayed, setIsChartDisplayed] = useState(userChartPreference)
  const { refreshBlockNumber, isLoading: isLoadings } = useRefreshBlockNumberID()
  useEffect(() => {
    setUserChartPreference(isChartDisplayed)
  }, [isChartDisplayed, setUserChartPreference])

  const listCache: WeakMap<TokenList, TokenAddressMap> | null =
    typeof WeakMap !== 'undefined' ? new WeakMap<TokenList, TokenAddressMap>() : null

  function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[]
  }

  function listToTokenMap(list: TokenList): TokenAddressMap {
    // const result = listCache?.get(list)
    // if (result) return results
    const tokenMap: WrappedTokenInfo[] = uniqBy(
      list.tokens,
      (tokenInfo) => `${tokenInfo.chainId}#${tokenInfo.address}`,
    ).map((tokenInfo) => {
      const tags: TagInfo[] =
        tokenInfo.tags
          ?.map((tagId) => {
            if (!list.tags?.[tagId]) return undefined
            return { ...list.tags[tagId], id: tagId }
          })
          ?.filter((x): x is TagInfo => Boolean(x)) ?? []
      return new WrappedTokenInfo(tokenInfo, tags)
    })
    const groupedTokenMap: { [chainId: string]: WrappedTokenInfo[] } = groupBy(
      tokenMap,
      (tokenInfo) => tokenInfo.chainId,
    )
    // console.log(groupedTokenMap, 'groupedTokenMapgroupedTokenMap')
    const tokenAddressMap = fromPairs(
      Object.entries(groupedTokenMap).map(([chainId, tokenInfoList]) => [
        chainId,
        fromPairs(tokenInfoList.map((tokenInfo) => [tokenInfo.address, { token: tokenInfo, list }])),
      ]),
    ) as TokenAddressMap
    // add chain id item if not exist
    enumKeys(ChainId).forEach((chainId) => {
      if (!(ChainId[chainId] in tokenAddressMap)) {
        Object.defineProperty(tokenAddressMap, ChainId[chainId], {
          value: {},
        })
      }
    })

    listCache?.set(list, tokenAddressMap)
    return tokenAddressMap
  }

  const mapWithoutUrls = (tokenMap: TokenAddressMap, chainId: number) =>
    Object.keys((tokenMap && tokenMap[chainId]) || {}).reduce<{ [address: string]: Token }>((newMap, address) => {
      // eslint-disable-next-line no-param-reassign
      newMap[address] = tokenMap[chainId][address].token
      return newMap
    }, {})

  // const parsedQueryChainId = Number(router.query.chainId)
  // const chainId = parsedQueryChainId || Number(localStorage.getItem('chainId')) || 17777
  // const userAddedTokens = useUserAddedTokens()
  const [listData, setListData] = useState<any>({})
  const { baseURL, chainid } = chainUtils()

  const [tvl, setTvl] = useState(0)
  const [volume, setVolume] = useState(0)

  useEffect(() => {
    // console.log('我是正常的打印🤖🤖🤖🤖🤖🤖', router, isMobile)
    const fetch = async () => {
      const defaultList = DEFAULT_TOKEN_LIST
      const { data, code } = await getRecommendCoin({ baseURL, chainid })
      let formatData = []
      if (!code) {
        // console.log('🧚🏼‍♂️🧚🏼‍♂️🧚🏼‍♂️🧚🏼‍♂️🧚🏼‍♂️🧚🏼‍♂️🧚🏼‍♂️请求GetCoinList')
        formatData =
          data?.map((v) => {
            const vv = v
            const { decimal } = vv
            delete vv.decimal
            return {
              ...vv,
              decimals: decimal,
              chainId: vv.chain_id,
              logoURI: '',
              address: vv.contract,
            }
          }) || []
        defaultList.tokens = [...formatData]
        const defaultTokenMap = listToTokenMap(defaultList)

        // console.log('最新的', defaultTokenMap)
        // const listDatas = userAddedTokens
        //   // reduce into all ALL_TOKENS filtered by the current chain
        //   .reduce<{ [address: string]: Token }>(
        //     (tokenMap_, token) => {
        //       // eslint-disable-next-line no-param-reassign
        //       tokenMap_[token.address] = token
        //       return tokenMap_
        //     },
        //     // must make a copy because reduce modifies the map, and we do not
        //     // want to make a copy in every iteration
        // mapWithoutUrls(defaultTokenMap, chainId),
        //   )
        const listDatas = mapWithoutUrls(defaultTokenMap, chainId)
        // console.log('最新的', listDatas)
        setListData(listDatas)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    const fetch = async () => {
      const { data, code } = await get24HInfo({ baseURL, chainid })
      if (!code) {
        setTvl(data?.quote_coin_balance || 0)
        setVolume(data?.volume_24h || 0)
      }
    }
    fetch()
  }, [])

  // token warning stuff
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId, listData),
    useCurrency(loadedUrlParams?.outputCurrencyId, listData),
  ]
  const urlLoadedTokens: Token[] = useMemo(
    () => [loadedInputCurrency, loadedOutputCurrency]?.filter((c): c is Token => c?.isToken) ?? [],
    [loadedInputCurrency, loadedOutputCurrency],
  )

  const { account, chainId } = useActiveWeb3React()

  // dismiss warning if all imported tokens are in active lists
  // const defaultTokens = useAllTokens()
  // 获取getCoinList接口
  // const defaultTokens = useCommonTokens()
  // const defaultTokens = {}
  // console.log(useCommonTokens(), 'useCommonTokens()useCommonTokens()')
  // 这个没有用到, 先注释
  // const importTokensNotInDefault =
  //   urlLoadedTokens &&
  //   urlLoadedTokens.filter((token: Token) => {
  //     return !(token.address in defaultTokens) && token.chainId === chainId
  //   })

  // for expert mode
  // 专家模式开关
  const [isExpertMode] = useExpertModeManager()

  // get custom setting values for user 设置滑点
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state & price data
  const {
    independentField, // INPUT/OUTPUT
    typedValue, // input value
    recipient,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
  } = useSwapState()
  // 根据address返回对应的币种类
  const inputCurrency = useCurrency(inputCurrencyId, listData)
  const outputCurrency = useCurrency(outputCurrencyId, listData)
  // const test = 1
  // console.log('v2Tradev2Tradev2Tradev2Trade', listData);  // 这里的 listData 是最新的数据)
  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
  } = useDerivedSwapInfo(independentField, typedValue, inputCurrency, outputCurrency, recipient, listData)

  const {
    wrapType,
    execute: onWrap,
    inputError: wrapInputError,
  } = useWrapCallback(currencies[Field.INPUT], currencies[Field.OUTPUT], typedValue)
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }
  // 封装各种swap后的操作
  const { onSwitchTokens, onCurrencySelection, onUserInput, onChangeRecipient } = useSwapActionHandlers()

  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      // console.log('value', value)
      onUserInput(Field.INPUT, value)
    },
    [onUserInput],
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput],
  )

  // modal and loading
  const [{ tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    tradeToConfirm: Trade<Currency, Currency, TradeType> | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  // if(formattedAmounts.OUTPUT){
  //   const now = new Date();
  //   const hours = now.getHours().toString().padStart(2, '0');
  //   const minutes = now.getMinutes().toString().padStart(2, '0');
  //   const seconds = now.getSeconds().toString().padStart(2, '0');
  //   const formattedTime = `${hours}:${minutes}:${seconds}`;
  //   console.log(formattedTime + '---得到的值', formattedAmounts.OUTPUT)
  // }

  const route = trade?.route
  // console.log('routerouterouteroute', route)
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(BIG_INT_ZERO),
  )
  const noRoute = !route

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage, chainId)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])
  // 获取input最大值
  const maxAmountInput: CurrencyAmount<Currency> | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage, recipient)

  const { priceImpactWithoutFee, realizedLPFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])

  const [singleHopOnly] = useUserSingleHopOnly()

  const handleSwap = useCallback(() => {
    if (!swapCallback) {
      return
    }
    onPresentConfirmModal()
    setSwapState({ attemptingTxn: true, tradeToConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then((hash) => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, swapErrorMessage: undefined, txHash: hash })
      })
      .catch((error) => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          swapErrorMessage: error.message,
          txHash: undefined,
        })
      })
  }, [priceImpactWithoutFee, swapCallback, tradeToConfirm, t])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn })
  }, [attemptingTxn, swapErrorMessage, trade, txHash])

  // swap warning state
  const [swapWarningCurrency, setSwapWarningCurrency] = useState(null)
  const [onPresentSwapWarningModal] = useModal(<SwapWarningModal swapCurrency={swapWarningCurrency} />, false)

  useEffect(() => {
    if (swapWarningCurrency) {
      onPresentSwapWarningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swapWarningCurrency])
  // input选择框回调
  const handleInputSelect = useCallback(
    (currencyInput) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, currencyInput)
      const showSwapWarning = shouldShowSwapWarning(currencyInput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyInput)
      } else {
        setSwapWarningCurrency(null)
      }

      replaceBrowserHistory('inputCurrency', currencyId(currencyInput))
    },
    [onCurrencySelection],
  )

  const handleMaxInput = useCallback(
    (num: any) => {
      // console.log('number', num)
      if (num) {
        onUserInput(Field.INPUT, num.toExact())
      }
      // if (maxAmountInput) {
      //   console.log('maxAmountInput',  maxAmountInput.toExact())
      //   onUserInput(Field.INPUT, maxAmountInput.toExact())
      // }
    },
    [maxAmountInput, onUserInput],
  )
  // console.log(maxAmountInput.toExact(), 'maxAmountInput.toExact()')
  // output选择框回调
  const handleOutputSelect = useCallback(
    (currencyOutput) => {
      onCurrencySelection(Field.OUTPUT, currencyOutput)
      const showSwapWarning = shouldShowSwapWarning(currencyOutput)
      if (showSwapWarning) {
        setSwapWarningCurrency(currencyOutput)
      } else {
        setSwapWarningCurrency(null)
      }

      replaceBrowserHistory('outputCurrency', currencyId(currencyOutput))
    },

    [onCurrencySelection],
  )

  const swapIsUnsupported = useIsTransactionUnsupported(currencies?.INPUT, currencies?.OUTPUT)
  // const swapIsUnsupported = false
  // const [onPresentImportTokenWarningModal] = useModal(
  //   <ImportTokenWarningModal tokens={importTokensNotInDefault} onCancel={() => router.push('/swap')} />,
  // )

  // useEffect(() => {
  //   if (importTokensNotInDefault.length > 0) {
  //     onPresentImportTokenWarningModal()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [importTokensNotInDefault.length])

  const [indirectlyOpenConfirmModalState, setIndirectlyOpenConfirmModalState] = useState(false)

  // const [onPresentSettingsModal] = useModal(
  //   <SettingsModalWithCustomDismiss
  //     customOnDismiss={() => setIndirectlyOpenConfirmModalState(true)}
  //     mode={SettingsMode.SWAP_LIQUIDITY}
  //   />,
  // )

  const clearInput = () => {
    onUserInput(Field.INPUT, '')
  }

  const pendingText = t('Exchange %num1% %coin1% for %num2% %coin2%', {
    num1: trade?.inputAmount?.toSignificant(6) ?? '',
    coin1: trade?.inputAmount?.currency?.symbol ?? '',
    num2: trade?.outputAmount?.toSignificant(6) ?? '',
    coin2: trade?.outputAmount?.currency?.symbol ?? '',
  })

  // const [{ successText, failText }, setText] = useState({
  //   successText: t('noah389'),
  //   failText: t('noah390'),
  // })
  const [onPresentConfirmModal] = useModal(
    // <ConfirmSwapModal
    //   trade={trade}
    //   originalTrade={tradeToConfirm}
    //   currencyBalances={currencyBalances}
    //   onAcceptChanges={handleAcceptChanges}
    //   attemptingTxn={attemptingTxn}
    //   txHash={txHash}
    //   recipient={recipient}
    //   allowedSlippage={allowedSlippage}
    //   onConfirm={handleSwap}
    //   swapErrorMessage={swapErrorMessage}
    //   customOnDismiss={handleConfirmDismiss}
    //   openSettingModal={onPresentSettingsModal}
    // />,
    <ResultModal
      pendingText={pendingText}
      hash={txHash}
      attemptingTxn={attemptingTxn}
      errorMessage={swapErrorMessage}
      successText={t('noah389')}
      failText={t('noah390')}
      clearInput={clearInput}
      isSuccess={() => {
        // console.log('执行合约成功了')
        // handleAcceptChanges()
        // getDataStart()
        // getData(account)
      }}
    />,
    true,
    true,
    'confirmSwapModal',
  )

  useEffect(() => {
    if (indirectlyOpenConfirmModalState) {
      setIndirectlyOpenConfirmModalState(false)
      setSwapState((state) => ({
        ...state,
        swapErrorMessage: undefined,
      }))
      onPresentConfirmModal()
    }
  }, [indirectlyOpenConfirmModalState, onPresentConfirmModal])

  const hasAmount = Boolean(parsedAmount)

  const onRefreshPrice = useCallback(() => {
    if (hasAmount) {
      refreshBlockNumber()
    }
  }, [hasAmount, refreshBlockNumber])

  const showRoute = Boolean(trade && trade.route.path.length)

  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  // console.log(slippageAdjustedAmounts, 'slippageAdjustedAmounts')
  // console.log(trade, 'trade')
  // console.log(allowedSlippage, 'allowedSlippage')

  let jumpHref = 'https://noah-finance.gitbook.io/noah/security/security-audit'
  if (langCode === 'zh-tw') {
    jumpHref = 'https://noah-finance.gitbook.io/noah/v/fan-ti/he-yao-an-quan/shen-ji-bao-gao'
  } else if (langCode === 'ko') {
    jumpHref = 'https://noah-finance.gitbook.io/noah/v/undefined/undefined-1/undefined'
  }
  return (
    <Page removePadding={isChartExpanded} hideFooterOnDesktop={isChartExpanded}>
      <div style={{ width: isMobile ? '100%' : '436px', paddingBottom: isMobile && '200px' }}>
        {
          chainId != 7200 ? (
            <Flex justifyContent="space-between" style={{ margin: '0 auto 20px' }}>
              <ButtonStyle isMobile={isMobile} style={{ width: '100%' }}>
                {langCode === 'zh-tw' && (
                  <Link href="/farms">
                    <img src="/images/ad/tw2.png" alt="" />
                  </Link>
                )}
                {langCode === 'en' && (
                  <Link href="/farms">
                    <img src="/images/ad/en2.png" alt="" />
                  </Link>
                )}
                {langCode === 'ko' && (
                  <Link href="/farms">
                    <img src="/images/ad/en2.png" alt="" />
                  </Link>
                )}
                <div
                  style={{
                    marginBottom: '10px',
                    padding: '0 20px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                  onClick={() => {
                    window.open('https://bridge.evm.eosnetwork.com/')
                  }}
                >
                  <Flex alignItems="center" justifyContent="center">
                    <Text mr="8px" fontWeight="600" fontSize="12px">
                      {t('noah211')}
                    </Text>
                    <img src="/images/small-img/icon4.png" style={{ width: '10px', height: '10px' }} alt="" />
                  </Flex>
                  <Flex>
                    <Text fontSize="12px" color="#0D111C">
                      {t('noah204')}
                    </Text>
                  </Flex>
                </div>
              </ButtonStyle>
              {/* <ButtonStyle
              isMobile={isMobile}
              onClick={() => {
                window.open('https://app.multichain.org/#/router')
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <Text mr="8px" fontWeight="600" fontSize="12px">
                  {t('noah212')}
                </Text>
                <img src="/images/small-img/icon4.png" style={{ width: '10px', height: '10px' }} alt="" />
              </Flex>
              <Flex>
                <Text fontSize="12px" color="#0D111C">
                  {t('noah206')}
                </Text>
              </Flex>
            </ButtonStyle> */}
            </Flex>
          ) : (
            <>
              <StyledSwapContainer $isChartExpanded={isChartExpanded} style={{ marginBottom: '20px' }}>
                <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
                  <AppBody noMaxWidth>
                    <Flex
                      justifyContent="space-between"
                      style={{ width: !isMobile && '436px', margin: '10px auto', padding: '10px 20px' }}
                    >
                      <TopBox isMobile={isMobile} style={{ width: '100%' }}>
                        <div className="label">TVL</div>
                        <div className="value">{formatNumber(truncateHasZero(tvl, 6), 1)} BTC</div>
                      </TopBox>

                      <TopBox isMobile={isMobile} style={{ width: '100%' }}>
                        <div className="label">24H Volume</div>
                        <div className="value">{formatNumber(truncateHasZero(volume, 6), 1)} BTC</div>
                      </TopBox>
                    </Flex>
                  </AppBody>
                </StyledInputCurrencyWrapper>
              </StyledSwapContainer>
            </>
          )
          // : (
          // <Flex justifyContent="space-between" style={{ width: !isMobile && '436px', margin: '0 auto 20px' }}>
          //   <ButtonStyle isMobile={isMobile} style={{ width: '100%', padding: '20px 0' }}>
          //     <div
          //       style={{
          //         marginBottom: '0px',
          //         padding: '0 20px',
          //         width: '100%',
          //         display: 'flex',
          //         justifyContent: 'space-between',
          //         alignItems: 'center',
          //       }}
          //       onClick={() => {
          //         window.open('https://faucet.exsat.network/')
          //       }}
          //     >
          //       <div
          //         style={{
          //           width: '100%',
          //           display: 'flex',
          //         }}
          //       >
          //         <img src="/images/ad/faucet.png" alt="" style={{ width: '60px' }} />
          //         <div
          //           style={{
          //             width: '100%',
          //             display: 'flex',
          //             flexDirection: 'column',
          //             justifyContent: 'center',
          //           }}
          //         >
          //           <Text mr="8px" fontWeight="600" fontSize="18px" mb={0}>
          //             {t('noah610')}
          //           </Text>
          //           <Text mr="8px" fontWeight="600" fontSize="14px" color="#999">
          //             {t('noah611')}
          //           </Text>
          //         </div>
          //       </div>
          //       <img src="/images/small-img/icon4.png" style={{ width: '14px', height: '14px' }} alt="" />
          //     </div>
          //   </ButtonStyle>
          /*{ <ButtonStyle
              isMobile={isMobile}
              onClick={() => {
                window.open('https://app.multichain.org/#/router')
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <Text mr="8px" fontWeight="600" fontSize="12px">
                  {t('noah212')}
                </Text>
                <img src="/images/small-img/icon4.png" style={{ width: '10px', height: '10px' }} alt="" />
              </Flex>
              <Flex>
                <Text fontSize="12px" color="#0D111C">
                  {t('noah206')}
                </Text>
              </Flex>
            </ButtonStyle> }
          </Flex>
        )*/
        }
        <Flex width="100%" justifyContent="center" position="relative">
          <Flex flexDirection="column" width="100%">
            <StyledSwapContainer $isChartExpanded={isChartExpanded}>
              <StyledInputCurrencyWrapper mt={isChartExpanded ? '24px' : '0'}>
                <AppBody noMaxWidth>
                  <CurrencyInputHeader
                    title={t('noah11')}
                    subtitle={t('noah147')}
                    setIsChartDisplayed={setIsChartDisplayed}
                    isChartDisplayed={isChartDisplayed}
                    hasAmount={hasAmount}
                    onRefreshPrice={onRefreshPrice}
                    allowedSlippage={allowedSlippage}
                  />
                  <Wrapper id="swap-page" style={{ minHeight: '320px' }}>
                    <AutoColumn gap="0">
                      <CurrencyInputPanel
                        label={
                          independentField === Field.OUTPUT && !showWrap && trade ? t('From (estimated)') : t('From')
                        }
                        value={formattedAmounts[Field.INPUT]}
                        showMaxButton={!atMaxAmountInput}
                        currency={currencies[Field.INPUT]}
                        onUserInput={handleTypeInput}
                        onMax={handleMaxInput}
                        onCurrencySelect={handleInputSelect}
                        otherCurrency={currencies[Field.OUTPUT]}
                        id="swap-currency-input"
                        showCommonBases
                        commonBasesType={CommonBasesType.SWAP_LIMITORDER}
                        listData={listData}
                      />

                      <AutoColumn justify="space-between" style={{ margin: '-14px 0', zIndex: '10' }}>
                        <AutoRow justify={isExpertMode ? 'space-between' : 'center'}>
                          <SwitchIconButton
                            variant="light"
                            scale="sm"
                            onClick={() => {
                              setApprovalSubmitted(false) // reset 2 step UI for approvals
                              onSwitchTokens()
                              replaceBrowserHistory('inputCurrency', outputCurrencyId)
                              replaceBrowserHistory('outputCurrency', inputCurrencyId)
                            }}
                          >
                            <img src="/images/small-img/swap.png" width={42} height={42} alt="swap" />
                          </SwitchIconButton>
                        </AutoRow>
                      </AutoColumn>
                      <CurrencyInputPanel
                        value={formattedAmounts[Field.OUTPUT]}
                        onUserInput={handleTypeOutput}
                        label={independentField === Field.INPUT && !showWrap && trade ? t('To (estimated)') : t('To')}
                        showMaxButton={false}
                        currency={currencies[Field.OUTPUT]}
                        onCurrencySelect={handleOutputSelect}
                        otherCurrency={currencies[Field.INPUT]}
                        id="swap-currency-output"
                        showCommonBases
                        commonBasesType={CommonBasesType.SWAP_LIMITORDER}
                        listData={listData}
                      />
                      {/* swap价格滑点路由数据 */}
                      {showWrap ? null : (
                        <AutoColumn gap="7px" style={{ padding: '0 16px', marginTop: '1rem' }}>
                          {Boolean(trade) && (
                            <>
                              {/* 兑换率 */}
                              <RowBetween align="center">
                                <Text fontSize="14px">{t('noah42')}</Text>
                                {isLoadings ? (
                                  <Skeleton width="50%" ml="10px" height="22px" />
                                ) : (
                                  <TradePrice
                                    price={trade?.executionPrice}
                                    showInverted={showInverted}
                                    setShowInverted={setShowInverted}
                                  />
                                )}
                              </RowBetween>
                              {/* 手续费 */}
                              <RowBetween>
                                <Text fontSize="14px">{t('noah43')}</Text>
                                <Text fontSize="14px">
                                  {realizedLPFee
                                    ? `${realizedLPFee?.toSignificant(6)} ${trade.inputAmount.currency.symbol}`
                                    : '-'}
                                </Text>
                              </RowBetween>
                              {/* 最低收到 */}
                              <RowBetween>
                                <Text fontSize="14px">{t('noah44')}</Text>
                                <Text fontSize="14px">
                                  {trade.tradeType === TradeType.EXACT_INPUT
                                    ? formatNumber(
                                        slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-',
                                        slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-',
                                      )
                                    : formatNumber(
                                        slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-',
                                        slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4) ?? '-',
                                      )}
                                </Text>
                              </RowBetween>
                              <RowBetween>
                                <Text fontSize="14px">{t('noah45')}</Text>
                                <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
                              </RowBetween>
                            </>
                          )}
                          {/* 兑换路径 */}
                          {showRoute && (
                            <RowBetween align="center">
                              <Text fontSize="14px">{t('noah46')}</Text>
                              <SwapRoute trade={trade} />
                            </RowBetween>
                          )}
                        </AutoColumn>
                      )}
                    </AutoColumn>
                    {/* { pair.} */}
                    {((inputCurrency?.symbol === 'EOS' && outputCurrency?.symbol === 'WEOS') ||
                      (inputCurrency?.symbol === 'WEOS' && outputCurrency?.symbol === 'EOS')) && (
                      <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', lineHeight: '20px' }}>
                        {/* {t('noah193')} */}
                        {t(
                          'The exchange rate between %coin1% and %coin2% is always 1:1 without any transaction fees.',
                          { coin1: 'WEOS', coin2: 'EOS' },
                        )}
                      </div>
                    )}

                    {((inputCurrency?.symbol === 'BTC' && outputCurrency?.symbol === 'XBTC') ||
                      (inputCurrency?.symbol === 'XBTC' && outputCurrency?.symbol === 'BTC')) && (
                      <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', lineHeight: '20px' }}>
                        {t(
                          'The exchange rate between %coin1% and %coin2% is always 1:1 without any transaction fees.',
                          { coin1: 'XBTC', coin2: 'BTC' },
                        )}
                      </div>
                    )}

                    {/* 按钮 */}

                    <Box mt="1rem">
                      {swapIsUnsupported ? (
                        // 不受支持的资产
                        <Button width="100%" disabled>
                          {t('noah143')}
                        </Button>
                      ) : !account ? (
                        // 连接钱包
                        <ConnectWalletButton width="100%" />
                      ) : showWrap ? (
                        <CommitButton width="100%" disabled={Boolean(wrapInputError)} onClick={onWrap}>
                          {!parsedAmount ? t('noah144') : t('noah11')}
                        </CommitButton>
                      ) : noRoute && userHasSpecifiedInputOutput ? (
                        <GreyCard style={{ textAlign: 'center', padding: '0.75rem' }}>
                          {/* 此交易的流动性不足。 */}
                          <Text color="textSubtle">{t('noah150')}</Text>
                          {/* 尝试启用多跳交易。 */}
                          {/* {singleHopOnly && <Text color="textSubtle">{t('Try enabling multi-hop trades.')}</Text>} */}
                        </GreyCard>
                      ) : showApproveFlow ? (
                        <RowBetween>
                          <CommitButton
                            variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                            onClick={approveCallback}
                            disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                            width="48%"
                          >
                            {approval === ApprovalState.PENDING ? (
                              <AutoRow gap="6px" justify="center">
                                {t('noah151')} <CircleLoader stroke="white" />
                              </AutoRow>
                            ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                              // 批准
                              t('noah54')
                            ) : (
                              `${t('noah54')} ${currencies[Field.INPUT]?.symbol ?? ''}`
                            )}
                          </CommitButton>
                          <CommitButton
                            onClick={() => {
                              if (priceImpactSeverity > 4) {
                                setSwapState({
                                  tradeToConfirm: trade,
                                  attemptingTxn: false,
                                  swapErrorMessage: undefined,
                                  txHash: undefined,
                                })
                                onPresentConfirmModal()
                              } else {
                                handleSwap()
                              }
                            }}
                            width="48%"
                            id="swap-button"
                            disabled={
                              !isValid || approval !== ApprovalState.APPROVED
                              // (priceImpactSeverity > 3 && !isExpertMode)
                            }
                          >
                            {/* {priceImpactSeverity > 3 && !isExpertMode ? t('noah152') : t('noah11')} */}
                            {t('noah11')}
                          </CommitButton>
                        </RowBetween>
                      ) : (
                        <CommitButton
                          variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'primary' : 'primary'}
                          isLoading={attemptingTxn}
                          onClick={() => {
                            if (priceImpactSeverity > 4) {
                              setSwapState({
                                tradeToConfirm: trade,
                                attemptingTxn: false,
                                swapErrorMessage: undefined,
                                txHash: undefined,
                              })
                              onPresentConfirmModal()
                            } else {
                              handleSwap()
                            }
                          }}
                          id="swap-button"
                          width="100%"
                          // disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                          disabled={!isValid || !!swapCallbackError}
                        >
                          {/* {swapInputError || (priceImpactSeverity > 3 && !isExpertMode ? t('noah152') : t('noah11'))} */}
                          {swapInputError || t('noah11')}
                        </CommitButton>
                      )}
                      {/* 批准进度条 */}
                      {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                    </Box>
                  </Wrapper>
                </AppBody>
                {/* {!swapIsUnsupported ? (
                  <></>
                ) : (
                  <UnsupportedCurrencyFooter currencies={[currencies.INPUT, currencies.OUTPUT]} />
                )} */}
              </StyledInputCurrencyWrapper>
            </StyledSwapContainer>
          </Flex>
        </Flex>
        <a
          href={jumpHref}
          target="_blank"
          style={{
            display: 'block',
            textAlign: 'center',
            margin: '25px auto 0',
            color: '#999',
            fontSize: isMobile ? '13px' : '14px',
            lineHeight: '20px',
          }}
          rel="noreferrer"
        >
          {chainId != 7200 ? t('noah135') : t('noah613')}
        </a>
      </div>
    </Page>
  )
}
